import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs = _resolveComponent("tm-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_tm_tabs, {
          modelValue: _ctx.currentRoute,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRoute) = $event)),
          tabs: _ctx.tabs,
          class: "mb-4",
          "use-router": ""
        }, null, 8, ["modelValue", "tabs"]),
        _createVNode(_component_router_view)
      ])
    ]),
    _: 3
  }))
}