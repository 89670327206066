
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTabs from '@/components/shared/TmTabs.vue'

export default defineComponent({
  components: {
    PageContent,
    TmTabs,
  },
  setup() {
    const currentRoute = ref('numbers')
    const tabs = ref([
      {
        name: 'numbers',
        label: 'Numbers',
        to: { name: 'base.services.senderSettings.numbers' },
      },
      {
        name: 'countries',
        label: 'Countries',
        to: { name: 'base.services.senderSettings.countries' },
      },
      {
        name: '10dlc',
        label: '10DLC registration',
        to: { name: 'base.services.senderSettings.10DLC' },
      },
      {
        name: 'toll-free',
        label: 'Toll-free verification',
        to: { name: 'base.services.senderSettings.tollFreeVerification' },
      },
      {
        name: 'senderIDs',
        label: 'Sender IDs',
        to: { name: 'base.services.senderSettings.senderIDs' },
      },
    ])

    return {
      currentRoute,
      tabs,
    }
  },
})
